export const HOME_URL = '/';
export const LOGIN_URL = '/login';
export const BASIC_FORECAST_URL = '/forecast';
export const FORECAST_URL = '/forecast/:code/:mode';
export const DEFAULT_FORECAST_URL = '/forecast/2023nsw/regular';
export const SEAT_DETAILS_URL = '/seat/:code/:mode/:seat';
export const ARCHIVE_LIST_URL = '/forecast/:code/archives';
export const ARCHIVE_URL = '/archive/:code/:id';
export const ARCHIVE_SEAT_URL = '/archive/:code/:id/seat/:seat';
export const GUIDE_URL = '/guide';
export const GLOSSARY_URL = '/glossary';
export const METHODOLOGY_URL = '/methodology';
export const COMMENTARY_URL = '/commentary';
export const COMMENTARY_SINGLE_URL = '/commentary/:id';
export const ABOUT_URL = '/about';
